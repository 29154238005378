import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SoftwareAssetMgmt = () => {
  return (
    <>
      <div className="text-center">
        <div className="qvalues ">
          <div className="peaks row justify-content-center peaks-wrapper">
            <div className="sam col-lg-3 col-sm-4 mb-2">
              <StaticImage
                placeholder="blurred"
                className="logo-static-img"
                imgClassName="img-fluid w-100 h-100"
                src="../../images/new-images/Budget-Icon.png"
                alt="budget"
                layout="constrained"
                width={162}
                loading="lazy"
              />
              <p className="h5 mt-0 text-black font-weight-bold">
                Maximize Your Software Budget
              </p>
              <p className>
                Unused tools can waste up to 30% of your IT budget. Our audit
                helps you cut IT waste, freeing up resources for more important,
                high-value projects.
              </p>
            </div>
            <div className="sam col-lg-3 col-sm-4 mb-2">
              <StaticImage
                placeholder="blurred"
                className="logo-static-img"
                imgClassName="img-fluid w-100 h-100"
                src="../../images/new-images/SMBs-Icon.png"
                alt="smbs"
                layout="constrained"
                width={162}
                loading="lazy"
              />
              <p className="h5 mt-0 text-black font-weight-bold">
                Designed for SMBs with Complex Systems
              </p>
              <p>
                Go beyond basic SAM tools. Our audit reviews your entire tech
                stack - IT, department apps, and custom/SaaS solutions - showing
                you how to optimize your technology and budget.
              </p>
            </div>
            <div className="sam col-lg-3 col-sm-4 mb-2">
              <StaticImage
                placeholder="blurred"
                className="logo-static-img"
                imgClassName="img-fluid w-100 h-100"
                src="../../images/new-images/Time-Icon.png"
                alt="time"
                layout="constrained"
                width={162}
                loading="lazy"
              />
              <p className="h5 mt-0 text-black font-weight-bold">
                Reclaim Your Time and Drive Business Growth
              </p>
              <p>
                Instead of adding to your workload, we’ll implement cost-saving
                solutions for you. With our agile approach, we focus on the
                highest-impact changes first, while securing your tech stack.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SoftwareAssetMgmt
